

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.App {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(4, 37, 70);
}

.phone-container {
  width: 100%;
  max-width: 576px;
  min-width: 260px;
  height: 100%;
  background-image: url("./constants/assets/iphone-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.copyright {
  position: fixed;
  bottom: 10px;
  right: 10px;
  box-shadow: var(--shadow-elevation-medium);
  padding: 5px 10px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: black;
  z-index: 99;
}

.debug {
  position: fixed;
  top: 10px;
  right: 10px;
  height: 25px;
  box-shadow: var(--shadow-elevation-medium);
  padding: 5px;
  padding-top: 3px;
  background-color: white;
  border-radius: 4px;
  display:block;
  align-items: center;
  color: black;
  z-index: 99;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}



.editor {
  position: fixed;
  bottom: 10px;
  left: -5px;
  box-shadow: var(--shadow-elevation-medium);
  padding: 5px 10px;
  background-color: rgb(223, 24, 57);
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: black;
  z-index: 99;
}

.message {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 38%;
  width: 70%;
  bottom: 38%;
  left: 15%;

  box-shadow: var(--shadow-elevation-medium);
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 4px;
  color: black;
  z-index: 300;
}

.developer {
  color: rgb(4, 37, 70);
  margin-right: 10px;
  font-weight: 500;
}

.fullh {

  height: 100%;

  display: flex;

  flex-direction: row;
  background-color: rgb(223, 24, 57);
  border: 1px solid silver;

  overflow-y: hidden;

}

.fullw {

  width: 100%;
  display: flex;

  flex-direction: row;
  background-color: rgb(223, 24, 57);
  border: 1px solid silver;

  overflow-x: hidden;
}
